<template>
  <component
    :is="messageComponent"
    :message="message"
    :has-next="hasNext"
    :same-minute="isWithinSameMinute">
    <component
      v-if="isBubble"
      :is="messageTypeComponent"
      :message="message"/>
  </component>
</template>

<script>
export default {
  components: {
    MessageAudio: () => import('@/components/chat/message/MessageAudio.vue'),
    MessageBubble: () => import('@/components/chat/message/MessageBubble.vue'),
    MessageDocument: () => import('@/components/chat/message/MessageDocument.vue'),
    MessageImage: () => import('@/components/chat/message/MessageImage.vue'),
    MessageLocation: () => import('@/components/chat/message/MessageLocation.vue'),
    MessageNotification: () => import('@/components/chat/message/MessageNotification.vue'),
    MessageText: () => import('@/components/chat/message/MessageText.vue'),
    MessageVideo: () => import('@/components/chat/message/MessageVideo.vue'),
  },
  props: {
    message: {
      required: true,
      type: Object,
    },
    hasNext: {
      default: false,
    },
    isWithinSameMinute: {
      default: false,
    },
  },
  computed: {
    isBubble() {
      return ['Client', 'User', 'UserSystem'].includes(this.message.typeUser);
    },
    messageComponent() {
      return this.isBubble ? 'message-bubble' : 'message-notification';
    },
    messageTypeComponent() {
      switch (this.message.type) {
        case 'tText':
        case 'text':
          return 'message-text';
        case 'image':
          return 'message-image';
        case 'video':
          return 'message-video';
        case 'audio':
        case 'voice':
          return 'message-audio';
        case 'document':
          return 'message-document';
        case 'location':
          return 'message-location';
        default:
          return '';
      }
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~styles/components/chat/_index.scss';
</style>
